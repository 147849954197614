<template>
  <r-block
    v-loading="loading"
    width="80vw"
    no-padding
  >
    <r-block
      no-padding
      start
      col
    >
      <r-button
        type="success"
        :disabled="!hasChanges"
        :loading="loading"
        @click="saveChanges"
      >
        Сохранить
      </r-button>
      <r-button
        simple
        @click="$emit('close')"
      >
        Выйти без сохранения
      </r-button>
    </r-block>
    <controls-comp
      :filter-text="filterText"
      :filters="filters"
      filter-placeholder="Поиск по № / ГРЗ"
      no-background
      no-border
      @handle-filter-text="filterText = $event"
      @reset-filters="loadViolations"
      @load-filter="updateFilters"
    />
    <r-block
      col
      no-padding
      top
    >
      <selected-list
        :selected-list="selectedList"
        :active-list="activeList"
        @click-handler="addedClick"
      />
      <r-block no-padding>
        <r-button
          icon="add-plus"
          :disabled="!selectedTable.length"
          type="primary"
          @click="handleChange('add')"
        />
        <r-button
          icon="minus"
          :disabled="!activeList.length"
          @click="handleChange('remove')"
        />
      </r-block>
      <r-block no-padding>
        <div class="violation-table-wrap">
          <table-prime-simple
            v-if="ready && !loading"
            :data="filteredViolations"
            :columns="fields"
            @selected-changed="selectedChanged"
          />
        </div>
      </r-block>
    </r-block>
  </r-block>
</template>

<script>
import { filtersEncoder } from '@/utils'
import { buildUrl } from './helpers'
import { tableFields, filters } from './configs'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    controlsComp: () => import('@/components/bdd/acts/components/controls'),
    tablePrimeSimple: () => import('@/components/table-prime-simple/table-prime-simple'),
    selectedList: () => import('./selected-list')
  },
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      loading: false,
      hasChanges: false,
      violations_id: '1b013139-c2d9-4641-a549-b162fce4eb57',
      source_id: this.$store.state.bdd.actsUrl,
      violationList: null,
      filters: filters(),
      fields: tableFields,
      filterText: '',
      violationCache: null,
      selectedTable: [],
      selectedList: [],
      activeList: []
    }
  },
  computed: {
    violations() {
      return this.source.act_violations?.map(e => {
        return {
          ...e,
          id_bak: e.id,
          id: e.bdd_violation_id,
          name: '№ ' + e?.bdd_violation?.no + ' / ГРЗ ' + (e?.bdd_violation?.vehicle?.reg_number || '—')
        }
      }) || []
    },
    actRelated() {
      return this.$store.state.bdd.actRelated || null
    },
    filteredViolations() {
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) return this.violationList || []

      const queryAttrs = ['reg_number', 'no']

      return this.violationList?.filter(p => {
        const filtered = queryAttrs
          .map(f => p[f])
          .join('')
          ?.trim()
          .toLowerCase()
        return filtered.includes(query)
      })
    }
  },
  async created() {
    this.violationCache = cloneDeep(this.violations)
    this.selectedList = this.violations

    await this.loadViolations()
    await this.setFilters()

    this.ready = true
  },
  methods: {
    handleChange(action) {
      switch (action) {
        case 'add':
          this.selectedList.push(...this.selectedTable?.map(e => ({
            ...e,
            name: '№ ' + e.no + ' / ГРЗ ' + (e.reg_number || '—')
          })))
          this.selectedTable = []
          this.loadViolations()
          this.hasChanges = true
          break
        case 'remove':
          this.selectedList = this.selectedList?.filter(s => !this.activeList?.find(a => a.id === s.id))
          this.activeList = []
          this.loadViolations()
          this.hasChanges = true
          break
      }
    },
    addedClick(val) {
      const index = this.activeList?.findIndex(s => s.id === val.id)

      if (index > -1) {
        this.activeList.splice(index, 1)
      } else {
        this.activeList.push(val)
      }
    },
    selectedChanged(val) {
      this.selectedTable = val
      this.$emit('changed')
    },
    setFilters() {
      this.filters = this.filters.map(f => {
        let filter = f
        if (f.source) {
          filter = {
            ...f,
            prop: this.actRelated[f.source].data.map(d => {
              const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

              return {
                ...d,
                value: fValue
              }
            })
          }
        }
        return {
          ...filter
        }
      })
    },
    updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.loadViolations()
    },
    async loadViolations() {
      try {
        this.loading = true
        const filters = filtersEncoder(this.filters)
        const excludedItems = {
          field: 'id',
          value: this.selectedList?.map(s => s.id),
          op: '!in'
        }

        filters.where.push(excludedItems)

        const { contractor_id } = this.source

        if (contractor_id) {
          filters.where.push({
            field: 'contractor_id',
            value: contractor_id,
            op: '='
          })
        }

        const url = buildUrl(this.violations_id, filters)
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.setListData(data)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    setListData(data) {
      const getRelatedValue = (value, source) => {
        const relatedItem = this.actRelated[source]

        return relatedItem?.data?.find(d => d.id === value)?.name || '—'
      }
      this.violationList = Object.values(data)?.map(d => {
        const item = { id: d.id, norm_doc_requirements: d.norm_doc_requirements }

        this.fields.forEach(f => {
          switch (f.tableType) {
            case 'related':
              item[f.title] = getRelatedValue(d?.[f.title], f.related)
              break
            case 'custom': {
              const [first, second] = f.customTitle.split('.')

              item[f.title] = d?.[first]?.[second]
              break
            }
            case 'datetime':
              item[f.title] = d?.[f.title] ? this.$rDate.format(d?.[f.title]) : '—'
              break
            default:
              item[f.title] = d?.[f.title]
              break
          }
        })

        return item
      })
    },
    async saveChanges() {
      try {
        this.loading = true

        const item = this.getData()

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data: item
        })
        this.$emit('handle-update')
        this.$emit('close')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getData() {
      const item = {
        id: this.source.id
      }
      const throughItems = this.violationCache
      const changed = this.selectedList
      const deleted = throughItems?.filter(th => {
        return !changed?.find(ch => ch.id === th.id)
      })
      const added = changed?.filter(cs => {
        return !throughItems?.find(ti => ti.id === cs.id)
      })

      const value = []

      value.push(...added?.map(v => {
        return {
          bdd_violation_id: v.id,
          act_id: this.source.id,
          norm_doc_requirements: v.norm_doc_requirements
        }
      }))
      value.push(...deleted?.map(v => {
        return {
          id: v.id_bak,
          disabled: true
        }
      }))

      item.act_violations = value

      return item
    }
  }
}
</script>

<style lang="scss">
.violation-table-wrap {
  display: grid;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  overflow-x: auto;

  > * {
    min-width: 640px;
  }
}
</style>
