export const tableFields = [
  {
    name: '№ Нарушения',
    title: 'no',
    type: 'string'
  },
  {
    name: 'Дата создания',
    title: 'created_at',
    tableType: 'datetime',
    type: 'string'
  },
  {
    name: 'Дата фиксации',
    title: 'datetime',
    tableType: 'datetime',
    type: 'string'
  },
  {
    name: 'ГРЗ',
    title: 'reg_number',
    customTitle: 'vehicle.reg_number',
    tableType: 'custom',
    type: 'string'
  },
  {
    name: 'ФИО выявившего нарушение',
    title: 'detection_employee_id',
    related: 'employees',
    tableType: 'related',
    type: 'string'
  },
  {
    name: 'Вид нарушения',
    title: 'type_id',
    related: 'bdd_type',
    tableType: 'related',
    type: 'string'
  },
  {
    name: 'Подрядчик',
    title: 'contractor_id',
    related: 'contractor',
    tableType: 'related',
    type: 'string'
  },
  {
    name: 'Описание нарушения',
    title: 'description',
    type: 'string'
  }
]

export const filters = () => ([
  {
    id: 'created_at',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'datetime',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата фиксации',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'type_id',
    source: 'bdd_type',
    type: 'checkbox-filter',
    title: 'Вид нарушения',
    prop: [],
    active: false
  },
  {
    id: 'contractor_id',
    source: 'contractor',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  },
  {
    id: 'detection_employee_id',
    source: 'employees',
    type: 'checkbox-filter',
    title: 'ФИО выявившего нарушение',
    prop: [],
    active: false
  }
])
