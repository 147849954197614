export const buildUrl = (source_id, filters) => {
  const options = {
    only: [
      'id',
      'no',
      'created_at',
      'type_id',
      'datetime',
      'detection_employee_id',
      'norm_doc_requirements',
      'contractor_id',
      'description'
    ],
    include: {
      vehicle: {
        only: ['reg_number']
      }
    }
  }
  const order = [{ created_at: 'desc' }]

  const config = JSON.stringify({
    ...filters,
    order,
    ...options
  })
  return `objectInfo/${source_id}?config=${config}`
}
